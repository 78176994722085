import axios from "axios";
import { Language } from "../../../Utils/language";
import "./news.scss";
import { useEffect, useState } from "react";
import NewsCard from "../../Cards/News/NewsCard";
import Loading from "../../Cards/Loading/Loading";

export default function News() {
    const [data, setData] = useState([]);
    const [archive, setArchive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dogs, setDogs] = useState([]);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const results = await axios.get(
                    `https://api.bellamare.pl/news?_order=DESC&_sort=addedAt`
                );

                setData(results.data);
                setLoading(false);
            } catch (error) {
                return "error";
            }
        };

        fetchData();
    }, [archive]);

    useEffect(() => {
        const fetchDogs = async () => {
            const results = await axios.get("https://api.bellamare.pl/dogs");

            setDogs(results.data);
        };

        fetchDogs();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="news">
            <div className="news_title">{Language.news_title}</div>
            <div className="news_container">
                {data && data.length > 0
                    ? archive
                        ? data
                              .filter(
                                  (f) =>
                                      new Date(f.addedAt).getFullYear() !==
                                          new Date().getFullYear() &&
                                      new Date(f.addedAt).getFullYear() !==
                                          new Date().getFullYear() - 1
                              )
                              .map((news) => (
                                  <NewsCard
                                      key={news.id}
                                      news={news}
                                      dogs={dogs}
                                  />
                              ))
                        : data.filter(
                              (f) =>
                                  new Date(f.addedAt).getFullYear() ===
                                      new Date().getFullYear() ||
                                  new Date(f.addedAt).getFullYear() ===
                                      new Date().getFullYear() - 1
                          ).length > 0
                        ? data
                              .filter(
                                  (f) =>
                                      new Date(f.addedAt).getFullYear() ===
                                          new Date().getFullYear() ||
                                      new Date(f.addedAt).getFullYear() ===
                                          new Date().getFullYear() - 1
                              )
                              .map((news) => (
                                  <NewsCard
                                      key={news.id}
                                      news={news}
                                      dogs={dogs}
                                  />
                              ))
                        : Language.notCurrentNews
                    : Language.notCurrentNews}

                {data.filter(
                    (f) =>
                        new Date(f.addedAt).getFullYear() <
                        new Date().getFullYear()
                ).length ? (
                    <div>
                        {data.filter(
                            (f) =>
                                new Date(f.addedAt).getFullYear() <
                                new Date().getFullYear()
                        ) ? (
                            <div
                                className="news_archive"
                                onClick={() => setArchive(!archive)}
                            >
                                {!archive
                                    ? Language.archive_title
                                    : Language.back_title}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}
