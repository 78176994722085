import cookies from "react-cookies";

//save PL cookies
if (!cookies.load("language")) {
    cookies.save("language", "pl", { path: "/" });
}

const lg = cookies.load("language");

export const Language = {
    aboutbreed_title: lg === "pl" ? "O rasie" : "About Breed",
    home_title: lg === "pl" ? "Home" : "Home",
    contact_title: lg === "pl" ? "Kontakt" : "Contact",
    news_title: lg === "pl" ? "Nowości" : "News",
    dogs_title: lg === "pl" ? "Psy" : "Males",
    females_title: lg === "pl" ? "Suczki" : "Females",
    youngsters_title: lg === "pl" ? "Młodzież" : "Youngsters",
    puppies_title: lg === "pl" ? "Szczenięta" : "Puppies",
    puppies_why_title:
        lg === "pl"
            ? "Dlaczego szczenię z dobrej hodowli?"
            : "Why a puppy from a good breeder?",
    current_litters_title: lg === "pl" ? "Aktualne Mioty" : "Current Litters",
    planned_litters_title: lg === "pl" ? "Planowane Mioty" : "Planned Litters",
    aboutus_title: lg === "pl" ? "O nas" : "About Us",
    archive_title: lg === "pl" ? "Archiwum" : "Archives",
    back_title: lg === "pl" ? "Powrót" : "Back",
    males_memory_title: lg === "pl" ? "Były w hodowli" : "DOGS FROM OUR PAST",
    females_memory_title: lg === "pl" ? "Były w hodowli" : "GIRLS OF THE PAST",
    youngsters_memory_title:
        lg === "pl" ? "WYHODOWANE PRZEZ NAS" : "Dogs we bred",
    notCurrentNews:
        lg === "pl"
            ? "Nie mamy dla was nowych aktualności."
            : "We have no new news for you.",
    notInfo_Aboutus: lg === "pl" ? "Brak informacji" : "Lack of information",
    noDogs:
        lg === "pl" ? "Brak psów w bazie danych!" : "No dogs in the database",
    frozen_semen:
        lg === "pl" ? "Dostepne mrożone nasienie!" : "frozen semen available!",
    pedigree_title: lg === "pl" ? "Rodowód" : "Pedigree",
    gallery_title: lg === "pl" ? "Galeria" : "Gallery",
    females_coop:
        lg === "pl"
            ? "Suczki stanowiące współwłasność mieszkają ze swoim drugim właścicielem."
            : "Some of the dogs that are co-owned live with their other owners",
    dogs_coop:
        lg === "pl" ? (
            <p>
                Psy stanowiące współwłasność mieszkają ze swoim drugim
                właścicielem, <br></br>
                dlatego zainteresowanych kryciem prosimy o wcześniejszy
                <a href="https://bellamare.pl/contact"> kontakt</a>.<br></br>
                Wysyłamy świeże, schłodzone nasienie
            </p>
        ) : (
            <p>
                Some of the dogs that are co-owned live with their other owners{" "}
                <br />
                Fresh chilled semen shipped worldwide
            </p>
        ),

    contact_data:
        lg === "pl" ? (
            <>
                <p>Sonia Czajor</p>
                <p>Bella Mare FCI - Labrador Retriever</p>
                <p>komórka + 48 509 925 385</p>
                <p>
                    email:{" "}
                    <a href="mailto://bellamare@wp.pl">bellamare@wp.pl</a>{" "}
                </p>
                <p>Nowy adres hodowli: Wejherowo, Pomorskie, Polska</p>
            </>
        ) : (
            <>
                <p>Sonia Czajor</p>
                <p>Bella Mare FCI - Labrador Retriever</p>
                <p>Phone: + 48 509 925 385</p>
                <p>
                    email:{" "}
                    <a href="mailto://bellamare@wp.pl">bellamare@wp.pl</a>{" "}
                </p>
                <p>New address of the kennel: Wejherowo, Pomorskie, Poland</p>
            </>
        ),
    birthDate_title: lg === "pl" ? "Data urodzenia: " : "Birth Date: ",
    father_title: lg === "pl" ? "Ojciec" : "Father",
    mother_title: lg === "pl" ? "Matka" : "Mother",
};
